import styled from "styled-components";

const HorizontalPadding = styled.div<{ padding: string }>`
  position: relative;
  padding: 0px ${(p) => p.padding};
`;

const MaxWidth = styled.div<{ max: string }>`
  position: relative;
  max-width: ${(p) => p.max};
  box-sizing: border-box;
`;

const CenterContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
`;

const MinWidth = styled.div<{ min: string }>`
  position: relative;
  min-width: ${(p) => p.min};
  box-sizing: border-box;
`;

// put into utils? Really useful, no?
export const PageContent = (
  props: React.PropsWithChildren<{
    minWidth: string;
    maxWidth: string;
    padding: string;
  }>
) => {
  return (
    <MinWidth min={props.minWidth}>
      <HorizontalPadding padding={props.padding}>
        <CenterContent>
          <MaxWidth max={props.maxWidth}>
            <CenterContent>{props.children}</CenterContent>
          </MaxWidth>
        </CenterContent>
      </HorizontalPadding>
    </MinWidth>
  );
};
