import { animated, config, easings, useTransition } from "@react-spring/web";
import styled from "styled-components";

type NavigationTransformation = {
  from: string;
  enter: string;
  leave: string;
};

const FlowContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  box-sizing: border-box;
`;

const toTopTransformation: NavigationTransformation = {
  from: "translateY(100%)",
  enter: "translateY(0%)",
  leave: "translateY(-100%)",
};

const toLeftTransformation: NavigationTransformation = {
  from: "translateX(100%)",
  enter: "translateX(0%)",
  leave: "translageX(-100%)",
};

const transConfig = {
  ...config.default,
  duration: 600,
  easing: easings.easeInOutCubic,
};

const AnimatedPageContainer = (
  props: React.PropsWithChildren<{
    style: any;
  }>
) => {
  return (
    <animated.div
      style={{
        ...props.style,
        position: "absolute",
        width: "100%",
        height: "100%",
      }}
    >
      {props.children}
    </animated.div>
  );
};

export const Flow = (props: {
  pageId: number;
  ContentFn: (pageId: number) => JSX.Element;
  direction: "toTop" | "toLeft";
}): JSX.Element => {
  const transformation =
    props.direction === "toLeft" ? toLeftTransformation : toTopTransformation;

  const transitions = useTransition(props.pageId, {
    keys: null,
    from: { transform: transformation.from },
    enter: { transform: transformation.enter },
    leave: { transform: transformation.leave },
    initial: null,
    config: transConfig,
  });

  return (
    <FlowContainer>
      {transitions((style, i) => {
        return (
          <AnimatedPageContainer style={style}>
            {props.ContentFn(i)}
          </AnimatedPageContainer>
        );
      })}
    </FlowContainer>
  );
};
