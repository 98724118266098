import { ScreenSizeContext } from "context/screen-context";
import { useContext } from "react";
import styled from "styled-components";
import { BaseText } from "utils/base";
import { PageContent } from "utils/page-content";
import { Spacer } from "utils/spacer";

const Text = styled(BaseText)<{ fontSize?: string }>`
  font-weight: 400;
  font-size: ${(p) => p.fontSize ?? "20px"};
  text-align: center;
  color: white;
  line-height: 150%;
`;

const Title = styled(BaseText)`
  font-weight: 700;
  font-size: 40px;
  text-align: center;
  color: #010415;
  padding: 20px;
  background-color: #f7efff;
  border-radius: 5px;
`;

const BulletPoint = styled(Title)`
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding: 7px 7px;
  //   min-width: 230px;
`;

const Content = (props: React.PropsWithChildren<{}>) => {
  return (
    <PageContent minWidth="300px" maxWidth="700px" padding="20px">
      {props.children}
    </PageContent>
  );
};

export const About2 = () => {
  const screen = useContext(ScreenSizeContext);
  let fontSize = "20px";

  if (screen === "small") {
    fontSize = "17px";
  }

  return (
    <Content>
      <Spacer height="60px" />
      <Title>Vad går spelet ut på?</Title>
      <Spacer height="30px" />
      <Text fontSize={fontSize}>
        SNACKA SKIT är ett underhållande sällskapsspel för alla som vill ha en
        rolig kväll. Man kan säga att det är en mix mellan Charader, Cards
        Against Humanity och Tips från Coachen från tv-programmet Parlamentet.
      </Text>
      <Spacer height="30px" />
      <Text>
        Spelet går ut på att på bästa/roligaste/klockrenaste sättet illustrera
        och förkroppsliga ett uppdrag. Uppdragen kommer i form av en
        slumpmässigt ihopsatt kombination av VAD och HUR kort. VAD korten
        berättar för dig vad du ska prata om och HUR korten berättar på vilket
        sätt du ska göra det.
      </Text>
      <Spacer height="50px" />
      <Title>När ska man spela SNACKA SKIT? Och med vilka?</Title>
      <Spacer height="20px" />
      <BulletPoint>Efter tjejmiddagen</BulletPoint>
      <Spacer height="20px" />
      <BulletPoint>På parkvällen</BulletPoint>
      <Spacer height="20px" />
      <BulletPoint>Med familjen/släkten</BulletPoint>
      <Spacer height="20px" />
      <BulletPoint>På förfesten</BulletPoint>
      <Spacer height="20px" />
      <BulletPoint>I mellandagarna</BulletPoint>
      <Spacer height="20px" />
      <BulletPoint>På midsommar</BulletPoint>
      <Spacer height="50px" />
      <Title>Vem har gjort spelet? Kan man kontakta er?</Title>
      <Spacer height="20px" />
      <Text fontSize={fontSize}>
        <p>SNACKA SKIT är skapat och designat av Ella G.</p>
        <p>Hemsidan är kodad av Alexander A.</p>
        <p>
          För kärleksbrev, oklarheter och feedback tveka inte att maila till:
        </p>{" "}
        <p>ella.midsommarregn@gmail.com</p>
      </Text>
      <Spacer height="60px" />
    </Content>
  );
};
