import styled from "styled-components";

const ImgContainer = styled.img`
  height: 300px;
  width: 200px;
  border-radius: 20px;
  box-shadow: 0px 2px 50px rgba(22, 22, 22, 0.75);
`;

export function Card(props: { path: string }): JSX.Element {
  return <ImgContainer src={props.path} />;
}
