import { ScreenSize, ScreenSizeContext } from "context/screen-context";
import { useContext } from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  overflow: auto;
`;

const HeaderContainer = styled.div`
  position: relative;
  width: 100%;
  flex: 0 1 auto;
`;

const BodyContainer = styled.div<{ minHeight: string }>`
  position: relative;
  width: 100%;
  flex: 1 1 auto;
  min-height: ${(p) => p.minHeight};
`;

const FooterContainer = styled.div`
  position: relative;
  width: 100%;
`;

export function Layout(props: {
  header?: JSX.Element;
  body?: JSX.Element;
  footer?: JSX.Element;
  screen: "large" | "small";
}): JSX.Element {
  const screen = useContext(ScreenSizeContext);
  const bodyMinHeight = getBodyMinHeight(screen);

  return (
    <Container>
      <HeaderContainer>{props.header}</HeaderContainer>
      <BodyContainer minHeight={bodyMinHeight}>{props.body}</BodyContainer>
      <FooterContainer>{props.footer}</FooterContainer>
    </Container>
  );
}

function getBodyMinHeight(screen: ScreenSize) {
  if (screen === "small") {
    return "350px";
  }
  if (screen === "medium") {
    return "450px";
  }

  // for large
  return "550px";
}
