import { ScreenSizeContext } from "context/screen-context";
import { DecorationCards } from "home/decoration-cards";
import { Footer } from "home/footer";
import { Header } from "home/header";
import { Layout } from "home/layout";
import { useContext } from "react";
import { Background } from "utils/background";
import { MinWidth } from "utils/min-width";

export const Home = () => {
  const screen = useContext(ScreenSizeContext);
  const screenSmallOrLarge = screen === "small" ? "small" : "large";

  return (
    <MinWidth>
      <Background>
        <Layout
          screen={screenSmallOrLarge}
          header={<Header />}
          body={<DecorationCards />}
          footer={<Footer />}
        />
      </Background>
    </MinWidth>
  );
};
