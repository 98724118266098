import React from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { BaseButton, BaseText } from "utils/base";
import { PAGE_URL } from "utils/const";
import { PageContent } from "utils/page-content";
import { Spacer } from "utils/spacer";

const HeaderContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`;

const Logo = styled.img<{ maxWidth: number }>`
  max-width: ${(p) => p.maxWidth}px;
  object-fit: contain;
`;

const Fill = styled.div`
  position: relative;
  width: 100%;
`;

const FillLight = styled(Fill)`
  background-color: #f7efff;
`;

const FillDark = styled(Fill)`
  background-color: #561f91;
`;

export const foo = "foo";

const LargeTextBox = styled(BaseText)`
  font-weight: 700;
  font-size: 26px;
  color: #f7efff;
  background-color: #561f91;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
`;

const SmallTextBox = styled(BaseText)`
  font-weight: 700;
  font-size: 15px;
  color: #561f91;
  background-color: #f7efff;
  border-radius: 10px;
  text-align: center;
  padding: 14px;
  display: inline-block;
`;

const ContactText = styled(BaseText)`
  font-size: 15px;
  font-weight: 700;
  color: #561f91;
  text-align: center;
`;

const CenterContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;

const StartButton = styled(BaseButton)`
  font-weight: 700;
  font-size: 17px;
  padding: 15px;
  box-shadow: 0px 4px 7px rgba(22, 22, 22, 0.25);
  background-color: white;
  color: #561f91;
  border-radius: 5px;
`;

const GoToRulesButton = styled(BaseButton)`
  font-weight: 700;
  font-size: 12px;
  padding: 15px;
  box-shadow: 0px 4px 7px rgba(22, 22, 22, 0.25);
  background-color: #bf91f0;
  color: #f7efff;
  border-radius: 5px;
`;

const Content = (props: React.PropsWithChildren<{}>) => {
  return (
    <PageContent minWidth="300px" maxWidth="700px" padding="30px">
      {props.children}
    </PageContent>
  );
};

const BulletPoint = styled(BaseText)`
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  padding: 7px 7px;
  border-radius: 1000px;
  background-color: #f7efff;
  color: #561f91;
  min-width: 230px;
`;

export const About = () => {
  const navigate = useNavigate();

  const gotoRules = () => {
    navigate(PAGE_URL.rules);
  };

  const gotoPlay = () => {
    navigate(PAGE_URL.play);
  };

  return (
    <>
      <FillDark>
        <Spacer height="10px" />
        <HeaderContainer>
          <Logo src="snacka-skit-logga.png" alt="" maxWidth={170} />
          <StartButton onClick={gotoPlay}>STARTA SPEL</StartButton>
        </HeaderContainer>
        <Spacer height="10px" />
      </FillDark>
      <FillLight>
        <Content>
          <Spacer height="20px" />
          <LargeTextBox>Vad går spelet ut på?</LargeTextBox>
          <Spacer height="20px" />
        </Content>
      </FillLight>
      <FillDark>
        <Content>
          <Spacer height="30px" />
          <CenterContent>
            <SmallTextBox>
              SNACKA SKIT är ett underhållande sällskapsspel för alla som vill
              ha en rolig kväll. Man kan säga att det är en mix mellan Charader,
              Cards Against Humanity och Tips från Coachen från tv-programmet
              Parlamentet.
            </SmallTextBox>
          </CenterContent>
          <Spacer height="30px" />
          <CenterContent>
            <SmallTextBox>
              Spelet går ut på att på bästa/roligaste/klockrenaste sättet
              illustrera och förkroppsliga ett uppdrag. Uppdragen kommer i form
              av en slumpmässigt ihopsatt kombination av VAD och HUR kort. VAD
              korten berättar för dig vad du ska prata om och HUR korten
              berättar på vilket sätt du ska göra det.
            </SmallTextBox>
          </CenterContent>
          <Spacer height="30px" />
          <CenterContent>
            <SmallTextBox>
              Vissa kombinationer är lättare än andra och den som sedan bjuder
              på bäst show enligt dina medspelare vinner.
            </SmallTextBox>
          </CenterContent>
          <Spacer height="30px" />
          <CenterContent>
            <GoToRulesButton onClick={gotoRules}>
              Låter svinkul! Ta mig till reglerna
            </GoToRulesButton>
          </CenterContent>
          <Spacer height="30px" />
        </Content>
      </FillDark>
      <FillLight>
        <Content>
          <Spacer height="20px" />
          <LargeTextBox>
            När ska man spela SNACKA SKIT? Och med vilka?
          </LargeTextBox>
          <Spacer height="20px" />
        </Content>
      </FillLight>
      <FillDark>
        <Content>
          <Spacer height="20px" />
          <BulletPoint>Efter tjejmiddagen</BulletPoint>
          <Spacer height="20px" />
          <BulletPoint>På parkvällen</BulletPoint>
          <Spacer height="20px" />
          <BulletPoint>Med familjen/släkten</BulletPoint>
          <Spacer height="20px" />
          <BulletPoint>På förfesten</BulletPoint>
          <Spacer height="20px" />
          <BulletPoint>I mellandagarna</BulletPoint>
          <Spacer height="20px" />
          <BulletPoint>På midsommar</BulletPoint>
          <Spacer height="20px" />
        </Content>
      </FillDark>
      <FillLight>
        <Content>
          <Spacer height="20px" />
          <LargeTextBox>
            Vem har gjort spelet? Kan man kontakta er?{" "}
          </LargeTextBox>
          <Spacer height="30px" />
          <ContactText>
            <p>SNACKA SKIT är skapat och designat av Ella G.</p>
            <p>Hemsidan är kodad av Alexander A.</p>
            <p>
              För kärleksbrev, oklarheter och feedback tveka inte att maila
              till:
            </p>{" "}
            <p>ella.midsommarregn@gmail.com</p>
          </ContactText>
          <Spacer height="50px" />
        </Content>
      </FillLight>
    </>
  );
};
