import { Game } from "play/logic/game";
import { PlayCardDesktop } from "play/play-card";
import { PoppingButton } from "play/popping-button";
import { useEffect } from "react";
import styled from "styled-components";
import { Background } from "utils/background";
import { Flow } from "utils/flow";
import { Spacer } from "utils/spacer";

const HEADER_HEIGHT = "100px";

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`;

const BodyNavigationContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

const RoundContainer = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`;

const RoundFakeHeader = styled.div`
  position: relative;
  width: 100%;
  min-height: ${HEADER_HEIGHT};
`;

const RoundContentFrame = styled.div`
  position: relative;
  width: 100%;
  flex: 1 1 auto;
  min-height: 600px;
  padding 30px 0px;
`;

const CenterCards = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardsContainer = styled.div`
  position: relative;
  display: flex;
`;

const HeaderContainer = styled.div`
  top: 0px;
  position: absolute;
  width: 100%;
  height: ${HEADER_HEIGHT};
`;

const HeaderInnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
  box-sizing: border-box;
`;

const Round = (props: {
  roundIndex: number;
  game: Game;
  setGame: (game: Game) => void;
}) => {
  const { game, roundIndex, setGame } = props;

  const round = game.getRound(roundIndex);

  useEffect(() => {
    console.log("initialRenderOfRound", roundIndex);
  }, []);

  const onClick = (cardType: "what" | "how") => {
    game.flip(cardType);
    setGame(game.copy());
  };

  return (
    <RoundContainer>
      <RoundFakeHeader />
      <RoundContentFrame>
        <CenterCards>
          <CardsContainer>
            <PlayCardDesktop
              card={round.what}
              onClick={() => onClick("what")}
            />
            <Spacer width="50px" />
            <PlayCardDesktop card={round.how} onClick={() => onClick("how")} />
          </CardsContainer>
        </CenterCards>
      </RoundContentFrame>
    </RoundContainer>
  );
};

const Board = (props: {
  game: Game;
  setGame: (game: Game) => void;
  onCancel: () => void;
  onForward: () => void;
}) => {
  const { game, setGame, onCancel, onForward } = props;

  return (
    <Background>
      <Container>
        <BodyNavigationContainer>
          <Flow
            direction="toTop"
            pageId={game.roundNum}
            ContentFn={(pageId) => {
              return (
                <Round roundIndex={pageId} game={game} setGame={setGame} />
              );
            }}
          />
        </BodyNavigationContainer>
        <HeaderContainer>
          <HeaderInnerContainer>
            <PoppingButton type="cancel" label="Avbryt" onClick={onCancel} />
            <PoppingButton type="next" label="Nästa" onClick={onForward} />
          </HeaderInnerContainer>
        </HeaderContainer>
      </Container>
    </Background>
  );
};

export { Board as BoardDesktop };
