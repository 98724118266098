class CardSrc {
  total = 100;
  private folder: string;

  constructor(folder: string) {
    this.folder = folder;
  }

  getSrc(key: string): string {
    return `${this.folder}/${key}.png`;
  }

  allKeys() {
    const result: string[] = [];
    for (let i = 1; i <= this.total; i++) {
      result.push(`${i}`);
    }
    return result;
  }
}

export const WHAT_CARDS = new CardSrc("what-cards");
export const HOW_CARDS = new CardSrc("how-cards");
