import { HOW_CARDS, WHAT_CARDS } from "play/card-src";
import { Card } from "play/logic/card";

type Round = {
  what: Card;
  how: Card;
};

// need to think about how many cards that are needed, and the round.
// throw error if something is wrong.
// round offset needs to be added
export class Game {
  roundNum: number;
  private roundNumToIndexOffset: number;
  private rounds: Round[];

  constructor(args: {
    // call it turns?
    rounds: Round[];
    roundNum?: number;
    roundNumToIndexOffset?: number;
  }) {
    this.rounds = args.rounds;
    this.roundNumToIndexOffset = args.roundNumToIndexOffset ?? 0;
    this.roundNum = args.roundNum ?? 0;

    if (this.rounds.length < 4) {
      throw Error("the minim number of rounds in a game is 4");
    }

    if (this.rounds.length - 1 < this.currentIndex() - 2) {
      throw Error(
        "there always have to be at least 2 rounds after the current index"
      );
    }
  }

  indexForRoundNum(roundNum: number) {
    return roundNum - this.roundNumToIndexOffset;
  }

  static startNew() {
    const rounds = getRounds(WHAT_CARDS.allKeys(), HOW_CARDS.allKeys());
    const game = new Game({ rounds });
    game.preloadCommingRounds();
    return game;
  }

  newRound() {
    this.roundNum += 1;

    if (this.needsToReload()) {
      this.reload();
    }

    this.preloadCommingRounds();
  }

  getRound(roundNum: number) {
    const index = this.indexForRoundNum(roundNum);
    return this.rounds[index];
  }

  currentIndex() {
    return this.indexForRoundNum(this.roundNum);
  }

  currentRound() {
    return this.rounds[this.currentIndex()];
  }

  // what and how should be a type
  flip(cardType: "what" | "how") {
    const round = this.currentRound();
    if (cardType === "what") {
      round.what.flip();
    } else {
      round.how.flip();
    }
  }

  copy() {
    return new Game({
      rounds: [...this.rounds],
      roundNum: this.roundNum,
      roundNumToIndexOffset: this.roundNumToIndexOffset,
    });
  }

  private needsToReload() {
    const cardsLeft = this.rounds.length - 1 - this.currentIndex();
    return cardsLeft < 3;
  }

  private preloadCommingRounds() {
    const nextRound = this.getRound(this.roundNum + 1);
    const nextNextRound = this.getRound(this.roundNum + 2);

    if (nextRound) {
      this.preloadRound(nextRound);
    }

    if (nextNextRound) {
      this.preloadRound(nextNextRound);
    }
  }

  private preloadRound(round: Round) {
    this.preloadCard(round.what);
    this.preloadCard(round.how);
  }

  private preloadCard(card: Card) {
    preloadImg(card.getSrc());
  }

  private reload() {
    const newRounds = getRounds(WHAT_CARDS.allKeys(), HOW_CARDS.allKeys());

    const oldRounds = [
      this.getRound(this.roundNum - 1),
      this.getRound(this.roundNum),
      this.getRound(this.roundNum + 1),
      this.getRound(this.roundNum + 2),
    ];

    const rounds = oldRounds.concat(newRounds);

    // we need to increase the offset by the number of rounds that are
    // are *not* included in the new "rounds" array, but were there
    // before this reload() call.
    // For instance, if we have 5 cards and the current roundIndex is 2
    // we will include rounds at index 1, 2, 3, 4 in the "oldRounds" array
    // (which is then included in the beggning of "rounds"). The round
    // previously found at index 1 will be found at index 0.
    // Thus, we need to increase the offset by 1
    const lowestIndexOfIncludedOldRounds = this.indexForRoundNum(
      this.roundNum - 1
    );
    this.roundNumToIndexOffset += lowestIndexOfIncludedOldRounds;
    this.rounds = rounds;
  }
}

function getRounds(whatCardKeys: string[], howCardKeys: string[]) {
  const result: Round[] = [];
  const smallesLen =
    whatCardKeys.length < howCardKeys.length
      ? whatCardKeys.length
      : howCardKeys.length;

  const shuffeledWhatCardKeys = shuffle(whatCardKeys);
  const shuffeledHowCardKeys = shuffle(howCardKeys);

  for (let i = 0; i < smallesLen; i++) {
    const what = new Card(shuffeledWhatCardKeys[i], "what");
    const how = new Card(shuffeledHowCardKeys[i], "how");
    result.push({ what, how });
  }

  return result;
}

function preloadImg(src: string) {
  const img = new Image();
  img.src = src;
}

// from https://stackoverflow.com/a/2450976
function shuffle<T>(array: T[]) {
  const _arr = array.slice();
  let currentIndex = array.length;
  let randomIndex: number;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [_arr[currentIndex], _arr[randomIndex]] = [
      _arr[randomIndex],
      _arr[currentIndex],
    ];
  }

  return _arr;
}
