import { Link } from "react-router-dom";
import styled from "styled-components";
import { PAGE_URL } from "utils/const";
import { BaseButton, BaseLinkBtn } from "../utils/base";
import { Spacer } from "../utils/spacer";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const StartButton = styled(BaseLinkBtn)`
  background-color: white;
  padding: 20px 30px;
  border-radius: 13px;
  color: #39206f;
  font-size: 30px;
  font-weight: 800;
  transition: 100ms linear;

  &:hover {
    transform: scale(1.05);
  }
`;

const RulesButton = styled(BaseButton)`
  color: #b69cd1;
  font-size: 15px;
  font-weight: 400;

  &:hover {
    text-decoration: underline;
  }
`;

const RulesButtonLink = styled(BaseLinkBtn)`
  color: #b69cd1;
  font-size: 15px;
  font-weight: 400;

  &:hover {
    text-decoration: underline;
  }
`;

export function Footer(): JSX.Element {
  return (
    <Container>
      <Spacer height="20px" />
      {/* <StartButton onClick={props.onStart}>STARTA SPEL</StartButton> */}
      <StartButton to={PAGE_URL.play}>STARTA SPEL</StartButton>
      <Spacer height="20px" />
      <RulesButtonLink to={PAGE_URL.rules}>
        Du kan väl reglerna?
      </RulesButtonLink>
      <Spacer height="30px" />
    </Container>
  );
}
