import { LOGO_PATH, PAGE_URL } from "utils/const";
import styled from "styled-components";
import { BaseButton, BaseLinkBtn } from "utils/base";
import { Spacer } from "utils/spacer";

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const LogoContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  padding-bottom: 0px;
`;

const Logo = styled.img<{ maxWidth: string }>`
  //   max-width: ${(p) => p.maxWidth};
  width: 100%;
  object-fit: contain;
`;

const Button = styled(BaseLinkBtn)`
  font-weight: bold;
  font-size: 16px;
  color: white;
  padding: 7px 14px;
  border-radius: 50px;
  background-color: #8d54c9;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderPhone = (): JSX.Element => {
  return (
    <Container>
      <LogoContainer>
        <Logo src={LOGO_PATH} maxWidth="80%" />
      </LogoContainer>
      <Spacer height="30px" />
      <ButtonContainer>
        <Button to={PAGE_URL.about}>Om Spelet</Button>
      </ButtonContainer>
    </Container>
  );
};
