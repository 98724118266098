import { LOGO_PATH, PAGE_URL } from "utils/const";
import styled from "styled-components";
import { BaseButton, BaseLinkBtn } from "../utils/base";
import { Spacer } from "../utils/spacer";

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 30px;
  box-sizing: border-box;
`;

const Button = styled(BaseLinkBtn)`
  font-weight: bold;
  font-size: 16px;
  color: white;
  padding: 7px 14px;
  border-radius: 50px;

  -webkit-transition: background-color 100ms linear;
  -moz-transition: background-color 100ms linear;
  -o-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;

  &:hover {
    background-color: #8d54c9;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img<{ maxWidth: number }>`
  max-width: ${(p) => p.maxWidth}px;
  object-fit: contain;
`;

export function HeaderDesktop(): JSX.Element {
  return (
    <Container>
      <Logo src={LOGO_PATH} alt="" maxWidth={300} />
      <ButtonContainer>
        <Button to={PAGE_URL.about}>Om spelet</Button>
      </ButtonContainer>
    </Container>
  );
}
