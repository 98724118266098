import { ScreenSizeContext } from "context/screen-context";
import { BoardDesktop } from "play/board-desktop";
import { BoardPhone } from "play/board-phone";
import { Game } from "play/logic/game";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";
import { Background } from "utils/background";
import { PAGE_URL } from "utils/const";
import { MinWidth } from "utils/min-width";

export const Play = () => {
  const [game, setGame] = useState(() => Game.startNew());
  const screen = useContext(ScreenSizeContext);
  const navigate = useNavigate();

  const onCancel = () => {
    navigate(PAGE_URL.home);
  };

  console.log("=> Play", game);

  const onForward = () => {
    game.newRound();
    setGame(game.copy());
  };

  if (screen === "small") {
    return (
      <PageContainer>
        <BoardPhone
          onCancel={onCancel}
          onForward={onForward}
          game={game}
          setGame={setGame}
        />
      </PageContainer>
    );
  }
  return (
    <PageContainer>
      <BoardDesktop
        onCancel={onCancel}
        onForward={onForward}
        game={game}
        setGame={setGame}
      />
    </PageContainer>
  );
};

const PageContainer = (props: React.PropsWithChildren<{}>) => {
  return (
    <Background>
      <MinWidth>{props.children}</MinWidth>
    </Background>
  );
};
