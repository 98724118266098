import { HOW_CARD_PATH, WHAT_CARD_PATH } from "utils/const";
import { ScreenSizeContext } from "context/screen-context";
import { useContext } from "react";
import styled from "styled-components";
import { Card } from "./card";

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Scaled = styled.div<{ by: number }>`
  transform: scale(${(p) => p.by});
`;

const Rotated = styled.div<{ deg: string }>`
  transform: rotate(${(p) => p.deg}deg);
`;

const Flex = styled.div`
  display: flex;
`;

const RightCardContainer = styled.div`
  position: relative;
  left: -10px;
  top: 30px;
`;

const LeftCardContainer = styled.div`
  position: relative;
  left: 40px;
  top: 0px;
`;

const ScaleOnHover = styled.div<{ doNotShow?: boolean }>`
  ${(p) =>
    !p.doNotShow &&
    `
    &:hover {
    transform: scale(1.05);
  }
  transition: 100ms linear;
  `};
`;

function DecorationCardBase(props: {
  scale: number;
  scaleOnHover?: boolean;
}): JSX.Element {
  return (
    <Container>
      <Scaled by={props.scale}>
        <ScaleOnHover doNotShow={!props.scaleOnHover}>
          <Flex>
            <LeftCardContainer>
              <Rotated deg="-23">
                <Card path={WHAT_CARD_PATH} />
              </Rotated>
            </LeftCardContainer>
            <RightCardContainer>
              <Rotated deg="6">
                <Card path={HOW_CARD_PATH} />
              </Rotated>
            </RightCardContainer>
          </Flex>
        </ScaleOnHover>
      </Scaled>
    </Container>
  );
}

export function DecorationCards(): JSX.Element {
  const screen = useContext(ScreenSizeContext);

  if (screen === "small") {
    return <DecorationCardBase scale={0.7} scaleOnHover={false} />;
  }

  if (screen === "medium") {
    return <DecorationCardBase scale={1.1} scaleOnHover />;
  }

  return <DecorationCardBase scale={1.3} scaleOnHover />;
}
