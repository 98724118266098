import { ScreenSizeContext } from "context/screen-context";
import { HeaderDesktop } from "home/header-desktop";
import { HeaderPhone } from "home/header-phone";
import { useContext } from "react";

export const Header = () => {
  const screenSize = useContext(ScreenSizeContext);

  if (screenSize === "small") {
    return <HeaderPhone />;
  }

  return <HeaderDesktop />;
};
