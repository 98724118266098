import { ScreenSizeContextProvider } from "context/screen-context";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
// import "./App.css";
import { Home } from "pages/home";
import { Play } from "pages/play";
import { Rules } from "pages/rules";
import { About } from "pages/about";
import { Rules2 } from "pages/rules2";
import { About2 } from "pages/about2";
import { Helmet } from "react-helmet";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #39206F;
  }
`;

function App() {
  return (
    <>
      <GlobalStyle />
      <Helmet>
        <title>Snacka Skit</title>
      </Helmet>
      <Pages />
    </>
  );
}

function Pages() {
  return (
    <ScreenSizeContextProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/play" element={<Play />} />
          <Route path="/regler" element={<Rules />} />
          <Route path="/om" element={<About />} />
          <Route path="regler2" element={<Rules2 />} />
          <Route path="om2" element={<About2 />} />
        </Routes>
      </Router>
    </ScreenSizeContextProvider>
  );
}

export default App;
