import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BaseButton, BaseText } from "utils/base";
import { PAGE_URL } from "utils/const";
import { Spacer } from "utils/spacer";

const HeaderContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`;

const StdText = styled(BaseText)`
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  line-height: 17px;
`;

const StdTextWhite = styled(StdText)`
  color: white;
`;

const StdTextPink = styled(StdText)`
  color: #f8a9ff;
`;

const AboveTitleText = styled(StdTextWhite)`
  font-size: 9px;
`;

const TipsText = styled(StdTextWhite)`
  font-size: 25px;
  text-align: left;
`;

const StartButton = styled(BaseButton)`
  font-weight: 700;
  font-size: 17px;
  padding: 15px;
  box-shadow: 0px 4px 7px rgba(22, 22, 22, 0.25);
  background-color: white;
  color: #20a7c0;
  border-radius: 5px;
`;

const Fill = styled.div`
  position: relative;
  width: 100%;
`;

const FillLightBluish = styled(Fill)`
  background-color: #27b8c3;
`;

const FillDarkerBluish = styled(Fill)`
  background-color: #1e829c;
`;

const FillVeryLightBlusih = styled(Fill)`
  background-color: #e9feff;
`;

const Logo = styled.img<{ maxWidth: number }>`
  max-width: ${(p) => p.maxWidth}px;
  object-fit: contain;
`;

const CenterContent = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  padding: 0px 40px;
`;

const SnackSkitText = styled(StdTextWhite)`
  font-weight: 800;
  font-size: 20px;
  font-family: Inter, sans-serif;
  line-height: 17px;
  line-height: 23px;
`;

const SnackaSkitSpan = styled.span`
  color: #f8a9ff;
  font-style: italic;
`;

const MiddleSectionFrame = styled.div`
  width: 100%;
  max-width: 700px;
  padding: 0px 20px;
  box-sizing: border-box;
`;

const MiddleSectionPadding = styled.div`
  position: relative;
  padding: 10px 60px;
`;

const NumberedList = styled.ol``;

const BulletList = styled.ul``;

const ListItem = styled.li`
  position: relative;
  left: -20px;
  margin: 18px 0;
`;

const ListItemText = styled(StdTextWhite)`
  text-align: left;
`;

export const Rules = () => {
  const navigate = useNavigate();

  const gotoPlay = () => {
    navigate(PAGE_URL.play);
  };

  return (
    <>
      <FillLightBluish>
        <Spacer height="10px" />
        <HeaderContainer>
          <Logo src="snacka-skit-logga.png" alt="" maxWidth={170} />
          <StartButton onClick={gotoPlay}>STARTA SPEL</StartButton>
        </HeaderContainer>
        <Spacer height="10px" />
      </FillLightBluish>
      <FillDarkerBluish>
        <Spacer height="20px" />
        <CenterContent>
          <ContentContainer>
            <AboveTitleText>
              ÄR DU REDO ATT BJUDA PÅ DIG SJÄLV? ÄR DU REDO FÖR SKRATTFEST?
            </AboveTitleText>
            <Spacer height="7px" />
            <SnackSkitText>
              ÄR DU REDO ATT <SnackaSkitSpan>SNACKA SKIT</SnackaSkitSpan>?
            </SnackSkitText>
          </ContentContainer>
        </CenterContent>
        <Spacer height="20px" />
      </FillDarkerBluish>
      <FillLightBluish>
        <Spacer height="20px" />
        <CenterContent>
          <ContentContainer>
            <StdTextWhite>
              {" "}
              Reglerna är enkla.
              <br /> Målet är att ha kul.
            </StdTextWhite>
          </ContentContainer>
        </CenterContent>
        <Spacer height="20px" />
        <CenterContent>
          <MiddleSectionFrame>
            <FillVeryLightBlusih>
              <MiddleSectionPadding>
                <StdTextPink>
                  Vinnaren kan vara den med mest poäng i slutet eller den med
                  mest träningsvärk i magen dagen efter - beroende på hur man
                  ser på det.
                </StdTextPink>
              </MiddleSectionPadding>
            </FillVeryLightBlusih>
            <FillDarkerBluish>
              <MiddleSectionPadding>
                <Spacer height="10px" />
                <ListItemText>
                  <NumberedList>
                    <ListItem>
                      Samla ihop ett gäng vänner, främlingar eller familj. När
                      stämningen är rätt ta fram en mobil eller dator som kan
                      skickas runt och tryck på “STARTA SPEL” för att påbörja
                      ett nytt spel.
                    </ListItem>
                    <ListItem>
                      Ta även fram ett papper och en penna och utse en person
                      till sekreterare. Sekreteraren är ansvarig för att hålla
                      koll på poängen som alla samlar in under spelets gång.
                      Sekreteraren är självklart med i spelet som vanligt utöver
                      rollen som poängräknare.
                    </ListItem>
                    <ListItem>
                      När det är den första spelarens tur vänder hen först på
                      VAD kortet. Beroende på vilken typ av VAD kort det är, kan
                      spelaren läsa upp vad det står. Läs t.ex ej upp SKÄMT
                      eller PICK UP LINES eftersom halva poängen försvinner då.
                      Därefter tar spelaren upp ett HUR kort och läser upp “hur”
                      uppdraget ska utföras.
                    </ListItem>
                    <ListItem>
                      Personen mittemot spelaren agerar alltid eventuell
                      "motspelare". Motspelaren byts ut därmed ut när det är
                      nästa persons tur. Motspelarens roll är att agera likt en
                      neutral programledare/konversationspartner. Motspelaren
                      får ifrågasätta, ställa motfrågor, lyssna, trissa upp och
                      hjälpa spelaren på traven. Det är spelaren som bestämmer
                      om den behöver ha en motspelare.
                    </ListItem>
                    <ListItem>
                      Spelaren ska nu göra sitt bästa för att förkroppsliga
                      uppdraget med VAD hen ska prata om och PÅ VILKET SÄTT hen
                      ska göra det. Beroende på vilken kortkombination som
                      spelaren fått, är det motspelarens tur att inleda showen
                      eller så kan spelaren klara det på egen hand. Målet är att
                      utföra “uppdraget” så roligt eller klockrent som möjligt.
                    </ListItem>
                    <ListItem>
                      Efter ca 30 sekunder till 1 minut är showen slut och nu är
                      det dags för resten av medspelarna att betygsätta
                      spelarens insats.
                    </ListItem>
                    <ListItem>
                      Gå laget runt och låt varje spelare välja mellan FLOPP
                      (det här flög inte riktigt), FLIPP (BRA JOBBAT hahaha)
                      eller SUCCÉ (Dööör av skratt! SÅÅ KLOCKREN!). Om
                      majoriteten av medspelarna tycker att det var en FLIPP får
                      spelaren 1 poäng. Om majoriteten av medspelarna tyckte det
                      var en FLOPP får spelaren 0 poäng. Och om minst 1 person
                      tycker att det var en SUCCÉ får spelaren 2 poäng. OBS! Är
                      det jämnt mellan FLIPP och FLOPP får spelaren ändå 1
                      poäng.
                    </ListItem>
                    <ListItem>
                      Första spelare till t.ex 5 poäng vinner. Anpassa
                      slutpoängen efter hur många ni är som spelar så att spelet
                      blir lagom långt. poäng.
                    </ListItem>
                  </NumberedList>
                </ListItemText>
              </MiddleSectionPadding>
            </FillDarkerBluish>
          </MiddleSectionFrame>
        </CenterContent>
      </FillLightBluish>
      <FillLightBluish>
        <CenterContent>
          <MiddleSectionFrame>
            <MiddleSectionPadding>
              <Spacer height="20px" />
              <TipsText>Tips</TipsText>
              <Spacer height="20px" />
              <ListItemText>
                <BulletList>
                  <ListItem>
                    För vissa kan det vara lättare att våga bjuda på sig själv
                    efter två glas alkohol men det är helt valfritt.
                  </ListItem>
                  <ListItem>
                    Ska du ha förfest? Kör SNACKA SKIT som ice breaker och gör
                    om reglerna till en dryckeslek. Är det FLIPP? Spelaren får
                    välja valfri annan medspelare som måste dricka. Är det
                    FLOPP? Spelaren måste själv dricka. Är det SUCCÉ - ja vad
                    händer då? Upp till er!
                  </ListItem>
                  <ListItem>
                    Snåla ej med att bedöma något som SUCCÉ! Är det en SUCCÉ så
                    ska spelaren få höra det - även om det innebär att hen får
                    fler poäng!
                  </ListItem>
                </BulletList>
              </ListItemText>
            </MiddleSectionPadding>
          </MiddleSectionFrame>
        </CenterContent>
      </FillLightBluish>
    </>
  );
};
