export const LOGO_PATH = "snacka-skit-logga.png";
export const WHAT_CARD_PATH = "what-card-main.png";
export const HOW_CARD_PATH = "how-card-main.png";

export const PAGE_URL = {
  home: "/",
  about: "/om",
  play: "/play",
  rules: "/regler",
};
