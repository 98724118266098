import { Link } from "react-router-dom";
import styled from "styled-components";

export const BaseButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  font-family: Inter, sans-serif;
`;

export const BaseText = styled.div`
  font-family: Inter, sans-serif;
`;

export const BaseLinkBtn = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  font-family: Inter, sans-serif;
`;
