import { ScreenSizeContext } from "context/screen-context";
import { useContext } from "react";
import styled from "styled-components";
import { BaseText } from "utils/base";
import { PageContent } from "utils/page-content";
import { Spacer } from "utils/spacer";

const Title = styled(BaseText)`
  font-weight: 700;
  font-size: 40px;
  text-align: center;
  color: #fda8ff;
`;

const Text = styled(BaseText)<{ fontSize?: string }>`
  font-weight: 400;
  font-size: ${(p) => p.fontSize ?? "20px"};
  text-align: left;
  color: white;
  line-height: 150%;
`;

const NumberedList = styled.ol``;

const BulletList = styled.ul``;

const ListItem = styled.li`
  position: relative;
  left: -10px;
  margin: 25px 0;
`;

// put into utils? Really useful, no?
const Content = (props: React.PropsWithChildren<{}>) => {
  return (
    <PageContent maxWidth="700px" minWidth="300px" padding="30px">
      {props.children}
    </PageContent>
  );
};

export const Rules2 = () => {
  const screen = useContext(ScreenSizeContext);

  let fontSize = "20px";

  if (screen === "small") {
    fontSize = "17px";
  }

  return (
    <Content>
      <Spacer height="60px" />
      <Title>Regler</Title>
      <Spacer height="30px" />
      <Text fontSize={fontSize}>
        <NumberedList>
          <ListItem>
            Samla ihop ett gäng vänner, främlingar eller familj. När stämningen
            är rätt ta fram en mobil eller dator som kan skickas runt och tryck
            på “STARTA SPEL” för att påbörja ett nytt spel.
          </ListItem>
          <ListItem>
            Ta även fram ett papper och en penna och utse en person till
            sekreterare. Sekreteraren är ansvarig för att hålla koll på poängen
            som alla samlar in under spelets gång. Sekreteraren är självklart
            med i spelet som vanligt utöver rollen som poängräknare.
          </ListItem>
          <ListItem>
            När det är den första spelarens tur vänder hen först på VAD kortet.
            Beroende på vilken typ av VAD kort det är, kan spelaren läsa upp vad
            det står. Läs t.ex ej upp SKÄMT eller PICK UP LINES eftersom halva
            poängen försvinner då. Därefter tar spelaren upp ett HUR kort och
            läser upp “hur” uppdraget ska utföras.
          </ListItem>
          <ListItem>
            Personen mittemot spelaren agerar alltid eventuell "motspelare".
            Motspelaren byts ut därmed ut när det är nästa persons tur.
            Motspelarens roll är att agera likt en neutral
            programledare/konversationspartner. Motspelaren får ifrågasätta,
            ställa motfrågor, lyssna, trissa upp och hjälpa spelaren på traven.
            Det är spelaren som bestämmer om den behöver ha en motspelare.
          </ListItem>
          <ListItem>
            Spelaren ska nu göra sitt bästa för att förkroppsliga uppdraget med
            VAD hen ska prata om och PÅ VILKET SÄTT hen ska göra det. Beroende
            på vilken kortkombination som spelaren fått, är det motspelarens tur
            att inleda showen eller så kan spelaren klara det på egen hand.
            Målet är att utföra “uppdraget” så roligt eller klockrent som
            möjligt.
          </ListItem>
          <ListItem>
            Efter ca 30 sekunder till 1 minut är showen slut och nu är det dags
            för resten av medspelarna att betygsätta spelarens insats.
          </ListItem>
          <ListItem>
            Gå laget runt och låt varje spelare välja mellan FLOPP (det här flög
            inte riktigt), FLIPP (BRA JOBBAT hahaha) eller SUCCÉ (Dööör av
            skratt! SÅÅ KLOCKREN!). Om majoriteten av medspelarna tycker att det
            var en FLIPP får spelaren 1 poäng. Om majoriteten av medspelarna
            tyckte det var en FLOPP får spelaren 0 poäng. Och om minst 1 person
            tycker att det var en SUCCÉ får spelaren 2 poäng. OBS! Är det jämnt
            mellan FLIPP och FLOPP får spelaren ändå 1 poäng.
          </ListItem>
          <ListItem>
            Första spelare till t.ex 5 poäng vinner. Anpassa slutpoängen efter
            hur många ni är som spelar så att spelet blir lagom långt.
          </ListItem>
        </NumberedList>
      </Text>
      <Spacer height="50px" />
      <Title>Tips </Title>
      <Spacer height="30px" />
      <Text fontSize={fontSize}>
        <BulletList>
          <ListItem>
            För vissa kan det vara lättare att våga bjuda på sig själv efter två
            glas alkohol men det är helt valfritt.
          </ListItem>
          <ListItem>
            Ska du ha förfest? Kör SNACKA SKIT som ice breaker och gör om
            reglerna till en dryckeslek. Är det FLIPP? Spelaren får välja valfri
            annan medspelare som måste dricka. Är det FLOPP? Spelaren måste
            själv dricka. Är det SUCCÉ - ja vad händer då? Upp till er!
          </ListItem>
          <ListItem>
            Snåla ej med att bedöma något som SUCCÉ! Är det en SUCCÉ så ska
            spelaren få höra det - även om det innebär att hen får fler poäng!
          </ListItem>
        </BulletList>
      </Text>
      <Spacer height="60px" />
    </Content>
  );
};
