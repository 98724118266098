import { useState } from "react";
import styled from "styled-components";
import { BaseButton } from "utils/base";

const Container = styled(BaseButton)<{ paddingTop: string }>`
  position: relative;
  padding-top: ${(p) => p.paddingTop};
  top: -${(p) => p.paddingTop};
  cursor: pointer;
`;

const Cover = styled.div<{
  moveUpOnHover: string;
  type: "cancel" | "next";
  isHovering: boolean;
}>`
  position: relative;
  font-size: 25px;
  font-weight: 600;
  padding: 13px;
  border-radius: 7px;

  ${(p) =>
    p.type === "cancel" &&
    `
    background-color: white;
    color: #473665;
  `}

  ${(p) =>
    p.type === "next" &&
    `
    background-color: #ff66c4;
    color: white;
  `}

  top: ${(p) => (p.isHovering ? `-${p.moveUpOnHover}` : "0px")};
  transition: top ease 0.2s;
`;

export const PoppingButton = (props: {
  type: "cancel" | "next";
  label: string;
  onClick: () => void;
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const moveUpOnHover = "5px";

  return (
    <Container
      paddingTop={moveUpOnHover}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={props.onClick}
    >
      <Cover
        moveUpOnHover={moveUpOnHover}
        type={props.type}
        isHovering={isHovering}
      >
        {props.label}
      </Cover>
    </Container>
  );
};
