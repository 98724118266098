import { Game } from "play/logic/game";
import { Card as CardModel } from "play/logic/card";
import { PlayCardPhone } from "play/play-card";
import styled from "styled-components";
import { BaseButton } from "utils/base";
import { Flow } from "utils/flow";

const HEADER_HEIGHT = "67px";

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`;

// body
const BodySheet = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

const BodyInsideNavigationContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const BodyFakeHeader = styled.div`
  position: relative;
  width: 100%;
  height: ${HEADER_HEIGHT};
`;

const BodyContentFrame = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - ${HEADER_HEIGHT});
  box-sizing: border-box;
  overflow: auto;
`;

const BodyContentContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 50px 30px;
  padding-bottom: 50px;
  box-sizing: border-box;
`;

const PlayCardContainer = styled.div`
  position: relative;
  width: 90%;
  max-width: 500px;
`;

// for some reason, the normal "Spacer" component does not work. See this
// https://stackoverflow.com/questions/4171286/how-to-make-a-div-with-no-content-have-a-width
const CardSpacer = styled.div`
  position: relative;
  width: 100%;
  min-height: 40px;
`;

const CarsBottomSpacer = styled.div`
  position: relative;
  width: 100%;
  min-height: 50px;
`;

// header
const HeaderSheet = styled.div`
  position: absolute;
  width: 100%;
  height: ${HEADER_HEIGHT};
`;

const HeaderContentContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
  box-sizing: border-box;
  border-bottom: 1px solid #816e99;
`;

const TopButton = styled(BaseButton)`
  position: relative;
  font-size: 18px;
  font-weight: 600;
  padding: 8px 10px;
  border-radius: 7px;
`;

const CancelButton = styled(TopButton)`
  background-color: white;
  color: #473665;
`;

const NextButton = styled(TopButton)`
  background-color: #ff66c4;
  color: white;
`;

const Card = (props: { card: CardModel; onClick: () => void }) => {
  return (
    <PlayCardContainer>
      <PlayCardPhone card={props.card} onClick={props.onClick} />
    </PlayCardContainer>
  );
};

const Round = (props: {
  roundIndex: number;
  game: Game;
  setGame: (game: Game) => void;
}): JSX.Element => {
  const { game, roundIndex, setGame } = props;

  const onClick = (cardType: "what" | "how") => {
    game.flip(cardType);
    setGame(game.copy());
  };

  const round = game.getRound(roundIndex);

  return (
    <BodyInsideNavigationContainer>
      <BodyFakeHeader />
      <BodyContentFrame>
        <BodyContentContainer>
          <Card card={round.what} onClick={() => onClick("what")} />
          <CardSpacer />
          <Card card={round.how} onClick={() => onClick("how")} />
          <CarsBottomSpacer />
        </BodyContentContainer>
      </BodyContentFrame>
    </BodyInsideNavigationContainer>
  );
};

export const Board = (props: {
  game: Game;
  setGame: (game: Game) => void;
  onCancel: () => void;
  onForward: () => void;
}): JSX.Element => {
  const { game, setGame, onCancel, onForward } = props;

  return (
    <Container>
      <BodySheet>
        <Flow
          direction="toLeft"
          pageId={game.roundNum}
          ContentFn={(pageId) => {
            return <Round roundIndex={pageId} game={game} setGame={setGame} />;
          }}
        />
      </BodySheet>
      <HeaderSheet>
        <HeaderContentContainer>
          <CancelButton onClick={onCancel}>Avbryt</CancelButton>
          <NextButton onClick={onForward}>Nästa</NextButton>
        </HeaderContentContainer>
      </HeaderSheet>
    </Container>
  );
};

export { Board as BoardPhone };
