import React, { useEffect } from "react";
import { useState } from "react";

export type ScreenSize = "small" | "medium" | "large";

export const breakpoints = {
  min: 300,
  small: 768,
  medium: 1025,
  large: 1440,
};

const screenWidthIsSize = (width: number): ScreenSize => {
  if (width <= breakpoints.small) {
    return "small";
  }

  if (width > breakpoints.small && width <= breakpoints.medium) {
    return "medium";
  }

  return "large";
};

export const ScreenSizeContext = React.createContext<ScreenSize>("medium");

export const ScreenSizeContextProvider = (
  props: React.PropsWithChildren<{}>
) => {
  const [size, setSize] = useState<ScreenSize>("medium");

  const resized = () => {
    const current = screenWidthIsSize(window.innerWidth);
    if (current !== size) {
      setSize(current);
    }
  };

  // call the resize func the first time this method is rendered
  useEffect(resized, []);

  useEffect(() => {
    window.addEventListener("resize", resized);
    return () => window.removeEventListener("resize", resized);
  });

  return (
    <ScreenSizeContext.Provider value={size}>
      {props.children}
    </ScreenSizeContext.Provider>
  );
};
