import styled from "styled-components";
import ReactCardFlip from "react-card-flip";
import { useContext, useState } from "react";
import { ScreenSize, ScreenSizeContext } from "context/screen-context";
import { BaseButton } from "utils/base";
import { Card } from "play/logic/card";

const BaseCardImg = styled.img`
  position: relative;
  border-radius: 20px;
  box-shadow: 0px 2px 50px rgba(22, 22, 22, 0.75);
`;

const DesktopCardImg = styled(BaseCardImg)<{
  cardHeight: string;
  moveUp: string | null;
}>`
  height: ${(p) => p.cardHeight};
  cursor: pointer;
  transition: 100ms linear;
  top: ${(p) => (p.moveUp !== null ? `-${p.moveUp}` : "0px")};
`;

const PopPlayCardContainer = styled(BaseButton)<{ paddingTop: string }>`
  position: relative;
  padding-top: ${(p) => p.paddingTop};
  top: -${(p) => p.paddingTop};
  cursor: pointer;
`;

const PhoneCardImg = styled(BaseCardImg)`
  width: 100%;
`;

export function PlayCardDesktop(props: {
  card: Card;
  onClick: () => void;
}): JSX.Element {
  const { card, onClick } = props;
  const [isHovering, setIsHovering] = useState(false);
  const screen = useContext(ScreenSizeContext);

  const cardHeight = getPlayCardDesktopHeight(screen);
  const moveUpOnHover = "10px";
  const moveCardUp = isHovering ? moveUpOnHover : null;

  return (
    <PopPlayCardContainer
      paddingTop={moveUpOnHover}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => setIsHovering(false)}
      onClick={onClick}
    >
      <ReactCardFlip isFlipped={card.isFlipped} flipDirection="horizontal">
        <DesktopCardImg
          src={card.getBacksideSrc()}
          cardHeight={cardHeight}
          moveUp={moveCardUp}
        />
        <DesktopCardImg
          src={card.getSrc()}
          cardHeight={cardHeight}
          moveUp={moveCardUp}
        />
      </ReactCardFlip>
    </PopPlayCardContainer>
  );
}

function getPlayCardDesktopHeight(screen: ScreenSize) {
  if (screen === "medium") {
    return "420px";
  }
  return "500px";
}

export function PlayCardPhone(props: {
  card: Card;
  onClick: () => void;
}): JSX.Element {
  const { card, onClick } = props;

  return (
    <ReactCardFlip isFlipped={card.isFlipped} flipDirection="horizontal">
      <PhoneCardImg src={card.getBacksideSrc()} onClick={onClick} />
      <PhoneCardImg src={card.getSrc()} onClick={onClick} />
    </ReactCardFlip>
  );
}
