import { HOW_CARDS, WHAT_CARDS } from "play/card-src";
import { HOW_CARD_PATH, WHAT_CARD_PATH } from "utils/const";

export class Card {
  private key: string;
  private type: "what" | "how";
  isFlipped: boolean;

  constructor(key: string, type: "what" | "how", isFlipped?: boolean) {
    this.key = key;
    this.type = type;
    this.isFlipped = isFlipped ?? false;
  }

  getKey() {
    return this.key;
  }

  getSrc() {
    if (this.type === "how") {
      return HOW_CARDS.getSrc(this.key);
    }
    return WHAT_CARDS.getSrc(this.key);
  }

  flip() {
    this.isFlipped = !this.isFlipped;
  }

  getBacksideSrc() {
    if (this.type === "how") {
      return HOW_CARD_PATH;
    }

    return WHAT_CARD_PATH;
  }
}
