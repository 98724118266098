import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: #150637;
  z-index: 0;
`;

const Filling = styled.div`
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
  transform: scale(1.3);
  background: radial-gradient(#8721d8, rgba(255, 255, 255, 0));
`;

const ContentContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: auto; // this is very problematic -> the background should be agnostic as to the content inside is.
`;

export function Background(props: React.PropsWithChildren<{}>): JSX.Element {
  return (
    <>
      <Container>
        <Filling />
        <ContentContainer>{props.children}</ContentContainer>
      </Container>
    </>
  );
}
